import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '$ui/section';
import Heading from '$ui/heading';

const LettersPage = () => {
  const {
    allCloudinaryMedia: { edges: images }
  } = useStaticQuery(graphql`
    query LettersImagesQuery {
      allCloudinaryMedia {
        edges {
          node {
            secure_url
            folder
          }
        }
      }
    }
  `);

  const letters = useMemo(
    () => images.filter(image => image.node.folder === 'frontend/letters'),
    [images]
  );

  return (
    <Section className='pt-32'>
      <Helmet>
        <title>TheCRO &mdash; Letters</title>
      </Helmet>
      <Heading>Important letters</Heading>

      <div className='grid grid-cols-[repeat(auto-fit,minmax(12rem,1fr))] gap-x-5 gap-y-10 px-40 mt-10 tab-port:px-8'>
        {letters.map((letter, i) => (
          <img key={i} src={letter.node.secure_url} alt='LETTER' />
        ))}
      </div>
    </Section>
  );
};

export default LettersPage;
